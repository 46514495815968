import { useState } from "react";
import LOGO from "../../assets/images/likha_new.png";
import Bable from "../../assets/images/Bable.png";
import { GrFacebookOption, GrTwitter } from "react-icons/gr";
import { BsDiscord } from "react-icons/bs";

const Nav = ({
  onsetHome,
  onsetAbout,
  onsetGallery,
  onsetRoadMap,
  onsetTeam,
  onsetCommunity,
  onsetFAQ,
}) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const genericHamburgerLine = ` h-1 w-6 my-1 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 transition ease transform duration-1000`;
  const genericHamburgerLineModal = `h-1 w-6 mb-1 rounded-full  bg-gradient-to-r from-purple-500 to-pink-500 transition ease transform duration-1000 `;

  function handleSection(e) {
    e();
    setIsNavOpen(false);
  }

  return (
    <div className=" z-50 flex items-center justify-between pt-5 ">
      <nav className="z-50" x-data="{ open: false }">
        <section className="MOBILE-MENU flex lg:visible">
          <div className="relative space-y-2.5 top-[-25px] left-40 px-8 py-8 max-sm:left-0 ">
            <a href="/">
              <img className="w-36 p-0 m-0" src={LOGO} alt="logo" />
            </a>
          </div>
          <div
            className="HAMBURGER-ICON fixed space-y-2 top-0 right-60 px-8 py-8 max-sm:right-0  "
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <div
              className={`${genericHamburgerLine} ${
                isNavOpen
                  ? " z-40 rotate-45 translate-y-3  opacity-50 group-hover:opacity-100"
                  : "opacity-50 group-hover:opacity-100"
              }`}
            />

            <div
              className={`${genericHamburgerLine} ${
                isNavOpen
                  ? "  z-40 -rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100 "
                  : "opacity-50 group-hover:opacity-100"
              }`}
            />
          </div>

          <div
            className={`${
              isNavOpen ? "showMenuNav" : "hideMenuNav"
            } h-auto w-auto`}
          >
            <div className="absolute space-y-2.5 top-0 left-40 px-8 py-8 max-sm:hidden">
              <a href="/">
                <img className="w-32 p-0 m-0" src={LOGO} alt="logo" />
              </a>
            </div>
            <div
              className="absolute top-0 right-[30rem] px-8 py-8 max-sm:right-0"
              onClick={() => setIsNavOpen(false)}
            >
              <div
                className={`${genericHamburgerLineModal} ${
                  isNavOpen
                    ? "rotate-45 translate-y-3 opacity-50 group-hover:opacity-100"
                    : "opacity-50 group-hover:opacity-100"
                }`}
              />
              <div
                className={`${genericHamburgerLineModal} ${
                  isNavOpen ? "opacity-0" : "opacity-50 group-hover:opacity-100"
                }`}
              />
              <div
                className={`${genericHamburgerLineModal} ${
                  isNavOpen
                    ? "-rotate-45 -translate-y-[4px] opacity-50 group-hover:opacity-100"
                    : "opacity-50 group-hover:opacity-100"
                }`}
              />
            </div>

            <ul className="font-bold text-start text-[#565655] absolute left-[1rem] lg:left-60 text-6xl ">
              <li className="border-gray-400  hover:scale-75 transition ease-in-out duration-700  ">
                <div className="p-1">
                  <button onClick={() => handleSection(onsetHome)}>HOME</button>
                </div>
              </li>
              <li className="border-gray-400   hover:scale-75 transition ease-in-out duration-700  ">
                <div className="p-1">
                  <button onClick={() => handleSection(onsetAbout)}>
                    ABOUT
                  </button>
                </div>
              </li>
              <li className="border-gray-400   hover:scale-75 transition ease-in-out duration-700   ">
                <div className="p-1">
                  <button onClick={() => handleSection(onsetGallery)}>
                    MARKETPLACE
                  </button>
                </div>
              </li>
              <li className="border-gray-400  hover:scale-75 transition ease-in-out duration-700  ">
                <div className="p-1">
                  <button onClick={() => handleSection(onsetRoadMap)}>
                    ROADMAP
                  </button>
                </div>
              </li>
              <li className="border-gray-400   hover:scale-75 transition ease-in-out duration-700">
                <div className="p-1">
                  <button onClick={() => handleSection(onsetTeam)}>TEAM</button>
                </div>
              </li>

              <li className="border-gray-400  hover:scale-75 transition ease-in-out duration-700">
                <div className="p-1">
                  <button onClick={() => handleSection(onsetCommunity)}>
                    COMMUNITY
                  </button>
                </div>
              </li>

              <li className="border-gray-400    hover:scale-75 transition ease-in-out duration-700">
                <div className="p-1">
                  <button onClick={() => handleSection(onsetFAQ)}>FAQ</button>
                </div>
              </li>

              <div className="flex space-x-12 md:space-x-9 justify-center md:w-5/12 px-4 pt-3 mt-4">
                <a href="www.google.com">
                  {" "}
                  <GrFacebookOption className="text-gray-300 icons" />{" "}
                </a>
                <a href="www.google.com">
                  {" "}
                  <GrTwitter className="text-gray-300 icons" />{" "}
                </a>
                <a href="www.google.com">
                  {" "}
                  <BsDiscord className="text-gray-300 icons" />{" "}
                </a>
              </div>
            </ul>

            <div className=" z-0 absolute right-0 w-[30%] h-screen bg-white max-sm:hidden"></div>
            <img
              className=" w-[30%] absolute bottom-[20%] right-[10%] max-sm:hidden"
              src={Bable}
              alt=""
            />
          </div>
        </section>
      </nav>

      <style>{`
        .hideMenuNav {
          display: none;
          
        }
        .showMenuNav {
          display: block;
          position: fixed;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background: #fdf2e5;
          z-index: 10;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: left;
          -webkit-animation: fadeinout 2s  ;
          animation: fadeinout 1s  ;
        }
  
        @-webkit-keyframes fadeinout {
          0% { opacity: 0; }
          50% { opacity: 1; }
        }
        
        
        
      `}</style>
    </div>
  );
};

export default Nav;
