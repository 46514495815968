import SectionSubTitle from "../../components/SectionSubTitle";
import SectionHeader from "../../components/SectionHeader";
import Content from "../../components/Content";
import RoadMapText from "../../components/RoadMapText";
import Two from "../../assets/images/2.webm";
import Three from "../../assets/images/3.webm";
import Four from "../../assets/images/4.webm";
import Five from "../../assets/images/5.webm";
import Holo2 from "../../assets/images/holo-copy-2.png";
import Holo11 from "../../assets/images/holo-copy-11.png";
import Holo13 from "../../assets/images/holo-copy-13.png";
import Holo20 from "../../assets/images/holo-copy-20.png";
import Holo21 from "../../assets/images/holo-copy-21.png";
import Holo22 from "../../assets/images/holo-copy-22.png";
import Holo23 from "../../assets/images/holo-copy-23.png";
import Holo24 from "../../assets/images/holo-copy-24.png";
import Holo25 from "../../assets/images/holo-copy-25.png";
import Holo26 from "../../assets/images/holo-copy-26.png";
import Holo27 from "../../assets/images/holo-copy-27.png";

const RoadMap = ({ setRef }) => {
  const titles = [
    "Likha's Roadmap:",
    "Making NFTs Accessible",
    "to the Next 10 Million",
    "Web3 Users",
  ];
  //"OUR GOAL", "IS TO MAKE NFTS", "AVAILABLE TO", "EVERY FILIPINO"]
  const holos = {
    first: {
      position: "right",
      icons: [
        {
          icon: Holo27,
          order: 1,
          size: 8,
          animate: "bounce",
        },

        {
          icon: Holo20,
          order: 2,
          size: 12,
          animate: "spin",
        },
      ],
    },

    second: {
      position: "left",
      icons: [
        {
          icon: Holo21,
          order: 1,
          size: 12,
          animate: "bounce",
        },

        {
          icon: Holo22,
          order: 2,
          size: 8,
          animate: "spin",
        },
      ],
    },

    third: {
      position: "right",
      icons: [
        {
          icon: Holo23,
          order: 1,
          size: 8,
          animate: "spin",
        },

        {
          icon: Holo26,
          order: 2,
          size: 12,
          animate: "bounce",
        },
      ],
    },

    fourth: {
      position: "left",
      icons: [
        {
          icon: Holo24,
          order: 1,
          size: 10,
          animate: "bounce",
        },

        {
          icon: Holo25,
          order: 2,
          size: 12,
          animate: "bounce",
        },
      ],
    },
  };

  return (
    <div ref={setRef} className="py-6 px-6 md:my-10 lg:mx-48 lg:my-20">
      <div className="flex flex-col relative">
        <img
          src={Holo2}
          alt="..."
          className="w-8 absolute top-10 left-[-80px] animate-bounce"
        ></img>
        <div className="flex flex-col md:items-start">
          <SectionSubTitle title="ROAD MAP" />
          <SectionHeader titles={titles} />
        </div>
        <img
          src={Holo13}
          alt="..."
          className="w-8 absolute right-10 md:right-80 md:bottom-10 lg:right-50 animate-bounce"
        ></img>
        <img
          src={Holo11}
          alt="..."
          className="w-14 absolute hidden lg:block bottom-[-100px] left-32 animate-bounce"
        ></img>
      </div>

      <div>
        <div className="flex flex-col lg:flex-row">
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5 order-2 lg:order-1">
            <RoadMapText
              title="Phase 1 (Q3 2022):"
              subtitle="Building the Foundation for Likha"
              parag="Develop features such as buying, selling, and trading of NFTs. "
            />
          </div>
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5 order-1 lg:order-2">
            <Content
              className="relative"
              bg="#D4B4F4"
              image={Two}
              holos={holos.first}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-5">
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5">
            <Content bg="#FFE1CB" image={Five} holos={holos.second} />
          </div>
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5">
            <RoadMapText
              title="Phase 2 (Q4 2022):"
              subtitle="Beta Testing"
              parag="Engage 500 users to test the platform, identify bugs, and gather valuable feedback."
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-5">
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5 order-2 lg:order-1">
            <RoadMapText
              title="Phase 3:"
              subtitle="Likha Commercial Launch - Expanding Features and Establishing Market Presence"
              parag="Likha's commercial launch introduces new features like LikhaAuth and LiPad, simplifying onboarding and showcasing top Filipino NFT projects. The platform opens up to everyone, enabling NFT minting and forging brand partnerships to expand its reach. User experience remains a priority, with continuous UI/UX optimization to ensure a seamless and enjoyable experience for users."
            />
          </div>
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5 order-1 lg:order-2">
            <Content bg="#ECC3A5" image={Three} holos={holos.third} />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row lg:space-x-5">
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5">
            <Content bg="#F9DCDC" image={Four} holos={holos.fourth} />
          </div>
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5">
            <RoadMapText
              title="Phase 4 (Q2 2023):"
              subtitle="Upload Feature Integration"
              parag="Allow users to upload important documents, such as business contracts, land titles, identification documents, and more."
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row">
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5 order-2 lg:order-1">
            <RoadMapText
              title="Phase 5 (Q4 2023):"
              subtitle="$LIKHA Token Launch"
              parag="The $LIKHA token is the driving force behind the Likha ecosystem, bolstering sustainable growth by employing milestone-based releases that align token distribution with the platform's progress. This approach ensures transparency and long-term value, while cashback rewards for NFT purchases enrich the user experience, fostering a dynamic and rewarding marketplace."
            />
          </div>
          <div className="flex justify-center items-center lg:w-1/2 w-full mt-5 order-1 lg:order-2">
            <Content
              className="relative"
              bg="#D4B4F4"
              image={Two}
              holos={holos.first}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
