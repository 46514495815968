import React from "react";
import SectionSubTitle from "../../components/SectionSubTitle";
import SectionHeader from "../../components/SectionHeader";
import FaqQuestion from "../../components/FaqQuestion";

import Holo13 from "../../assets/images/holo-copy-13.png";
import Holo23 from "../../assets/images/holo-copy-23.png";
import Holo24 from "../../assets/images/holo-copy-24.png";

const Faq = ({ setRef }) => {
  const titles = ["HAVE ANY", "QUESTIONS"];
  const faq = [
    {
      question: "What is an NFT?",
      answer:
        "An NFT (Non-Fungible Token) represents ownership or proof of authenticity of a unique item or piece of content, using blockchain technology. Unlike cryptocurrencies such as Bitcoin or Ethereum, NFTs are not interchangeable for other tokens of the same type but are unique in themselves.",
    },

    {
      question: "What is a blockchain?",
      answer:
        "A blockchain is a type of database that stores information in blocks that are chained together. As new data comes in, it is entered into a fresh block. When the block is filled with data, it is chained onto the previous block, making the data chained together in chronological order.",
    },

    {
      question: "What is the purpose of our NFT marketplace?",
      answer:
        "Our NFT marketplace provides a platform for the buying, selling, and trading of NFTs. It is centered around onboarding 10 million Web3 users, providing an accessible, easy-to-understand gateway into the world of NFTs and digital asset ownership.",
    },

    {
      question: "What are Web3 users?",
      answer:
        "Web3 users are individuals who interact with the decentralized internet, also known as Web 3.0. This new generation of internet users utilizes blockchain technology for peer-to-peer interactions, transactions, and experiences without intermediaries.",
    },

    {
      question: "How can I start buying or selling NFTs on this marketplace?",
      answer:
        "To start, you will need a digital wallet that supports Polygon, and some $MATIC in that wallet. Then, you can connect your wallet to our platform, browse available NFTs, and participate in the marketplace.",
    },

    {
      question: "Is it safe to buy NFTs from this platform?",
      answer:
        "Our platform utilizes blockchain technology, which ensures the security and authenticity of each transaction. However, like any online transaction, you should always conduct your own research and exercise caution.",
    },

    {
      question: "Can I create my own NFTs on this platform?",
      answer:
        "Yes, our platform provides tools for creators to mint their own NFTs and sell them on the marketplace. We offer guides and resources to help you get started.",
    },

    {
      question: "How are NFTs priced?",
      answer:
        "NFT pricing varies greatly and is typically set by the seller. Factors can include the reputation of the creator, perceived value of the NFT, rarity, demand, and other aspects.",
    },

    {
      question: "Do I need to pay transaction fees?",
      answer:
        'Yes, there are typically low transaction fees known as "gas fees" on the Polygon network. These fees go towards compensating the network\'s miners for validating your transactions. Fees can vary depending on network congestion.',
    },

    {
      question: "What happens if I lose access to my digital wallet?",
      answer:
        "It's crucial to keep your wallet information secure and backed up. If you lose access to your digital wallet, you may lose access to your NFTs, and they could be unrecoverable. Always make sure to keep your private keys safe at all times.",
    },

    {
      question: "Can I resell  my NFTs?",
      answer:
        "Yes, once you own an NFT, you have the right to sell it. You can list it for sale on our platform, set your price, and wait for a buyer.",
    },

    {
      question: "Can I earn royalties from my NFTs?",
      answer:
        "Yes, our platform supports programmable royalties. This means creators can earn a percentage of sales each time their NFT is resold on the secondary market.",
    },

    {
      question: "Can I return or refund an NFT?",
      answer:
        "Due to the nature of blockchain, transactions made with NFTs are typically irreversible. Once you purchase an NFT, it cannot be refunded unless the seller agrees to buy it back from you.",
    },

    {
      question: "How does copyright works with NFTs?",
      answer:
        "Buying an NFT often does not grant you the copyright of the work. Unless explicitly stated by the creator, buying an NFT usually means buying the ownership of a unique piece of data on the blockchain, not the intellectual property rights.",
    },

    {
      question: "What if I encounter a problem with a transaction?",
      answer:
        "If you encounter any problems, please contact our customer service. We'll do our best to assist you. However, due to the immutable nature of blockchain, some issues may be out of our control.",
    },
  ];

  /*

    import React from 'react'
import SectionSubTitle from '../../components/SectionSubTitle'
import SectionHeader from '../../components/SectionHeader'
import FaqQuestion from '../../components/FaqQuestion'
import Six from '../../assets/images/6.webm'

const Faq = () => {

    const titles = ["HAVE ANY", "QUESTIONS"]

    const faq = [
        {
            question: "What is an NFT?",
            answer: "An NFT (Non-Fungible Token) represents ownership or proof of authenticity of a unique item or piece of content, using blockchain technology. Unlike cryptocurrencies such as Bitcoin or Ethereum, NFTs are not interchangeable for other tokens of the same type but are unique in themselves."
        },

        {
            question: "What is a blockchain?",
            answer: "A blockchain is a type of database that stores information in blocks that are chained together. As new data comes in, it is entered into a fresh block. When the block is filled with data, it is chained onto the previous block, making the data chained together in chronological order."
        },

        {
            question: "What is the purpose of our NFT marketplace?",
            answer: "Our NFT marketplace provides a platform for the buying, selling, and trading of NFTs. It is centered around onboarding 10 million Web3 users, providing an accessible, easy-to-understand gateway into the world of NFTs and digital asset ownership."
        },

        {
            question: "What are Web3 users?",
            answer: "Web3 users are individuals who interact with the decentralized internet, also known as Web 3.0. This new generation of internet users utilizes blockchain technology for peer-to-peer interactions, transactions, and experiences without intermediaries."
        },

        {
            question: "How can I start buying or selling NFTs on this marketplace?",
            answer: "To start, you will need a digital wallet that supports Polygon, and some $MATIC in that wallet. Then, you can connect your wallet to our platform, browse available NFTs, and participate in the marketplace."
        },

        {
            question: "Is it safe to buy NFTs from this platform?",
            answer: "Our platform utilizes blockchain technology, which ensures the security and authenticity of each transaction. However, like any online transaction, you should always conduct your own research and exercise caution."
        },

        {
            question: "Can I create my own NFTs on this platform?",
            answer: "Yes, our platform provides tools for creators to mint their own NFTs and sell them on the marketplace. We offer guides and resources to help you get started."
        },

        {
            question: "How are NFTs priced?",
            answer: "NFT pricing varies greatly and is typically set by the seller. Factors can include the reputation of the creator, perceived value of the NFT, rarity, demand, and other aspects."
        },

        {
            question: "Do I need to pay transaction fees?",
            answer: "Yes, there are typically low transaction fees known as \"gas fees\" on the Polygon network. These fees go towards compensating the network's miners for validating your transactions. Fees can vary depending on network congestion."
        },

        {
            question: "What happens if I lose access to my digital wallet?",
            answer: "It's crucial to keep your wallet information secure and backed up. If you lose access to your digital wallet, you may lose access to your NFTs, and they could be unrecoverable. Always make sure to keep your private keys safe at all times."
        },

        {
            question: "Can I resell  my NFTs?",
            answer: "Yes, once you own an NFT, you have the right to sell it. You can list it for sale on our platform, set your price, and wait for a buyer."
        },

        {
            question: "Can I earn royalties from my NFTs?",
            answer: "Yes, our platform supports programmable royalties. This means creators can earn a percentage of sales each time their NFT is resold on the secondary market."
        },

        {
            question: "Can I return or refund an NFT?",
            answer: "Due to the nature of blockchain, transactions made with NFTs are typically irreversible. Once you purchase an NFT, it cannot be refunded unless the seller agrees to buy it back from you."
        },

        {
            question: "How does copyright works with NFTs?",
            answer: "Buying an NFT often does not grant you the copyright of the work. Unless explicitly stated by the creator, buying an NFT usually means buying the ownership of a unique piece of data on the blockchain, not the intellectual property rights."
        },

        {
            question: "What if I encounter a problem with a transaction?",
            answer: "If you encounter any problems, please contact our customer service. We'll do our best to assist you. However, due to the immutable nature of blockchain, some issues may be out of our control."
        }

    ]

    return (
        <div className="py-6 px-6 my-10 lg:mx-48 lg:my-20">
            <div className="flex flex-col xl:ml-52 mb-10">
                <div className="flex flex-col md:items-start">
                    <SectionSubTitle title="FAQ" />
                    <SectionHeader titles={titles} />
                </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:flex-wrap lg:justify-between">
                {faq.map((info, index) => (
                    <div key={index} className="w-full lg:w-[48%]">
                        <FaqQuestion question={info.question} answer={info.answer} />
                    </div>
                ))}
            </div>

        </div>
    )
}

export default Faq
*/

  return (
    <div ref={setRef} className="relative py-6 px-6 mb-10 lg:mx-48 lg:my-20">
      <div className="flex flex-col xl:ml-52 mb-10">
        <div className="relative flex flex-col md:items-start">
          <SectionSubTitle title="FAQ" />
          <SectionHeader titles={titles} />
          <img
            src={Holo13}
            alt="..."
            className="w-8 absolute top-50 right-60 animate-bounce"
          ></img>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:flex-wrap lg:justify-between">
        {faq.map((info, index) => (
          <div key={index} className="w-full lg:w-[48%]">
            <FaqQuestion question={info.question} answer={info.answer} />
          </div>
        ))}
      </div>

      <img
        src={Holo23}
        alt="..."
        className="w-8 absolute left-[-20px] top-80 animate-spin"
      ></img>
      <img
        src={Holo24}
        alt="..."
        className="w-14 absolute right-40 bottom-[-30px] animate-bounce"
      ></img>
    </div>
  );
};

export default Faq;
