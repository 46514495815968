import SectionSubTitle from "../../components/SectionSubTitle";
import SectionHeader from "../../components/SectionHeader";
import TeamMember from "../../components/TeamMember";
import Alex from "../../assets/images/team/likha_alex.png";
import Aldrin from "../../assets/images/team/likha_aldrin.png";
import Gelo from "../../assets/images/team/likha_gelo.png";
import Jason from "../../assets/images/team/likha_jason.png";
import Nikko from "../../assets/images/team/likha_nikko.png";
import Paul from "../../assets/images/team/likha_paul.png";
import Raph from "../../assets/images/team/likha_raph.png";
import Reika from "../../assets/images/team/likha_reika.png";
import Francis from "../../assets/images/team/francis_edt.png";
import Angelica from "../../assets/images/team/likha_angelica.jpg";

const Team = ({ setRef }) => {

  const titles = ["MEET THE", "TEAM THAT MADE", "IT HAPPEN"];
  const teamInfo = [
    {
      image: Gelo,
      bgColor: "#F1E1CB",
      bgGradient: "from-[#F1E1CB] via-[#F1E1CB] to-transparent",
      info: {
        name: "Gelo Wong",
        position: "Chief Executive Officer",
        socials: {
          linkedIn: "https://www.linkedin.com/in/gelowongg/",
          twitter: "https://twitter.com/gelowongg",
        },
      },
    },

    {
      image: Paul,
      bgColor: "#ECD5FC",
      bgGradient: "from-[#ECD5FC] via-[#ECD5FC] to-transparent",
      info: {
        name: "Paul Soliman",
        position: "Board of Director",
        socials: {
          linkedIn: "https://www.linkedin.com/in/paulsoliman//",
          twitter: "https://twitter.com/heypaulroots",
        },
      },
    },

    {
      image: Raph,
      bgColor: "#F1E1CB",
      bgGradient: "from-[#F1E1CB] via-[#F1E1CB] to-transparent",
      info: {
        name: "Raphael Sevilla ",
        position: "Chief Operations Officer",
        socials: {
          linkedIn: "https://www.linkedin.com/in/raphael-sevilla-26ba63145/",
          twitter: "https://twitter.com/raphsevi",
        },
      },
    },

    {
      image: Nikko,
      bgColor: "#E6ECF5",
      bgGradient: "from-[#E6ECF5] via-[#E6ECF5] to-transparent",
      info: {
        name: "Nikko Que",
        position: "Chief Evangelist Officer",
        socials: {
          linkedIn: "https://www.linkedin.com/in/nikkoque/",
          twitter: "https://twitter.com/nikkokwela",
        },
      },
    },

    {
      image: Alex,
      bgColor: "#F1E1CB",
      bgGradient: "from-[#F1E1CB] via-[#F1E1CB] to-transparent",
      info: {
        name: "Alexander Bryan Go",
        position: "Chief Financial Officer",
        socials: {
          linkedIn: "https://www.linkedin.com/in/alexander-bryan-go-73b8b0183/",
          twitter: "#",
        },
      },
    },

    {
      image: Jason,
      bgColor: "#E6ECF5",
      bgGradient: "from-[#E6ECF5] via-[#E6ECF5] to-transparent",
      info: {
        name: "Jason Cruz",
        position: "Chief Technology Officer",
        socials: {
          linkedIn: "#",
          twitter: "#",
        },
      },
    },

    {
      image: Reika,
      bgColor: "#ECD5FC",
      bgGradient: "from-[#ECD5FC] via-[#ECD5FC] to-transparent",
      info: {
        name: "Aya Reika Mayani",
        position: "Creative Director",
        socials: {
          linkedIn: "https://www.linkedin.com/in/aya-mayani-212925154/",
          twitter: "#",
        },
      },
    },

    {
      image: Francis,
      bgColor: "#E6ECF5",
      bgGradient: "from-[#E6ECF5] via-[#E6ECF5] to-transparent",
      info: {
        name: "Francis Viernes",
        position: "Director of Tokenomics",
        socials: {
          linkedIn: "https://www.linkedin.com/in/francisviernes/",
          twitter: "#",
        },
      },
    },

    {
      image: Aldrin,
      bgColor: "#F1E1CB",
      bgGradient: "from-[#F1E1CB] via-[#F1E1CB] to-transparent",
      info: {
        name: "Aldrin Dy",
        position: "Director of Business Development",
        socials: {
          linkedIn: "https://www.linkedin.com/in/aldrin-jared-sy-dy/",
          twitter: "https://twitter.com/Flay26NFT",
        },
      },
    },

    {
      image: Angelica,
      bgColor: "#ECD5FC",
      bgGradient: "from-[#ECD5FC] via-[#ECD5FC] to-transparent",
      info: {
        name: "Angelica So",
        position: "Gallery Director",
        socials: {
          linkedIn: "#",
          twitter: "https://twitter.com/angelicasoart",
        },
      },
    },
  ];

  return (
    <div ref={setRef} className="py-6 px-6 mt-10 lg:mx-48 lg:my-0">
      <div className="flex flex-col">
        <div className="flex flex-col md:items-start">
          <SectionSubTitle title="TEAM" />
          <SectionHeader titles={titles} />
        </div>
      </div>

      <div className="rounded-box mt-5 pb-10 w-full">
        <div className="flex flex-col md:flex-row items-center md:space-x-4 md:px-20 lg:px-32">
          <TeamMember teamInfo={teamInfo[0]} />
          <TeamMember teamInfo={teamInfo[1]} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
          <TeamMember teamInfo={teamInfo[2]} />
          <TeamMember teamInfo={teamInfo[3]} />
          <TeamMember teamInfo={teamInfo[4]} />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
          <TeamMember teamInfo={teamInfo[5]} />
          <TeamMember teamInfo={teamInfo[6]} />
          <TeamMember teamInfo={teamInfo[7]} />
        </div>
        <div className="flex flex-col md:flex-row items-center md:space-x-4 md:px-20 lg:px-32">
          <TeamMember teamInfo={teamInfo[8]} />
          <TeamMember teamInfo={teamInfo[9]} />
        </div>
      </div>

    </div>
  );
};

export default Team;
