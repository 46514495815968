import SectionSubTitle from "../../components/SectionSubTitle";
import SectionHeader from "../../components/SectionHeader";
import Pic1 from "../../assets/images/Image-01.png";
import Pic2 from "../../assets/images/Image-02.png";
import Pic3 from "../../assets/images/Image-03.png";
import Pic4 from "../../assets/images/Image-06.png";
import Pic5 from "../../assets/images/Image-07.png";
import Pic6 from "../../assets/images/Image-08.png";
import Pic7 from "../../assets/images/Image-11.png";
import Pic8 from "../../assets/images/Image-12.png";
import Pic9 from "../../assets/images/Image-13.png";

const GalleryCollection = ({ setRef }) => {

  const titles = ["LATEST 1/1 PIECES AND BRANDED COLLECTIONS"];

  return (
    <div ref={setRef} className="my-10 lg:my-20">
      <div className="py-6 px-6 lg:mx-36 relative">
        <div className="flex flex-col lg:flex-row lg:space-x-10 ">
          <div className="lg:w-7/12 w-full">
            <div className="flex flex-col md:items-start pb-10">
              <SectionSubTitle className="lg:ml-10" title="OUR FAVORITE" />
              <SectionHeader titles={titles} />
            </div>
          </div>
        </div>
      </div>

      <div className="carousel carousel-center">
          <div className="carousel-item py-1 pr-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic1} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic2} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic3} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic4} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic5} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic4} className="rounded-box" />
          </div>
          <div className="carousel-item py-1 pl-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic1} className="rounded-box" />
          </div>
        </div>

        <div className="carousel carousel-center">
          <div className="carousel-item py-1 pr-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic6} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic5} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic4} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic8} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic1} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic9} className="rounded-box" />
          </div>
          <div className="carousel-item py-1 pl-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic7} className="rounded-box" />
          </div>
        </div>

        <div className="carousel carousel-center">
          <div className="carousel-item py-1 pr-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic2} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic4} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic6} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic1} className="rounded-box" />
          </div>
          <div className="carousel-item p-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic8} className="rounded-box" />
          </div>
          <div className="carousel-item py-1 pl-1 w-[50%] md:w-[18%]">
            <img alt="..." src={Pic4} className="rounded-box" />
          </div>
        </div>
    </div>

  );
};

export default GalleryCollection;
