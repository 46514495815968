import { GrFacebookOption, GrTwitter } from "react-icons/gr";
import { AiFillInstagram } from "react-icons/ai";
import BayaniChainLogo from "../../assets/images/byc_hor_white.png";
import LikhaLogo from "../../assets/images/likha_new.png";

import "./footer.css";

const Footer = () => {
    return (

        <footer className="pt-10 pb-4 bg-gradient-to-r from-purple-500 to-pink-500">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="md:flex space-between md:-mx-4 py-6 lg:space-x-96">
                    <div className="md:w-3/5 px-4 text-left">
                        <img src={LikhaLogo} alt="..." className="w-1/2"></img>
                        <p className="text-white font-medium text-xs">The NFT platform built for world-class brands and artists. </p>
                        <span className="text-white font-medium text-xs"> Powered by <img src={BayaniChainLogo} alt="..." className="w-1/2 inline-block"></img> </span>
                    </div>
                    <div className="flex space-x-12 md:space-x-9 justify-center md:w-5/12 px-4 pt-3 mt-4">
                        <a href="https://www.facebook.com/likha.nft" target="_blank"  rel="noreferrer"> <GrFacebookOption className="text-white icons" /> </a>
                        <a href="https://twitter.com/LikhaNft" target="_blank" rel="noreferrer"> <GrTwitter className="text-white icons" /> </a>
                        <a href="https://www.instagram.com/likha.nft/" target="_blank" rel="noreferrer"> <AiFillInstagram className="text-white icons" /> </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
