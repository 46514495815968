import SectionSubTitle from "../../components/SectionSubTitle";
import SectionHeader from "../../components/SectionHeader";
import Button from "../../components/Button";
import Circle from "../../assets/images/circle.png";
import Elements from "../../assets/images/elements.png";

const Join = ({ setRef }) => {
  const link = {
    content: "Join Now",
    anchor: "https://www.facebook.com/groups/likhaancorner/",
  };

  const titles = ["JOIN OUR", "COMMUNITY"];
  return (
    <div
      ref={setRef}
      className="h-50 h-[700px] sm:h-[900px] xl:h-[1300px] relative flex justify-center items-center flex-col px-6 md:mx-20 overflow-hidden"
    >
      <SectionSubTitle title="IT'S TIME TO" />
      <SectionHeader titles={titles} centered />
      <div className="w-full md:w-1/2 my-5">
        <p className="text-lg font-bold text-gray-800 tracking-wider text-center">
          {" "}
          Connect with artists, collectors, and enthusiasts, and be a part of
          the future of digital art and collectibles.{" "}
        </p>
      </div>
      <Button link={link} />
      <img
        className="absolute animate-pulse object-cover lg:w-8/12"
        alt="..."
        src={Circle}
      ></img>
      <img
        className="absolute animate-spin-slow object-cover lg:w-9/12"
        alt="..."
        src={Elements}
      ></img>
    </div>
  );
};

export default Join;
