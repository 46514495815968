import React, { useState } from 'react'
import { GrLinkedinOption, GrTwitter } from 'react-icons/gr'

const TeamMember = ({ teamInfo }) => {
    const { bgColor, bgGradient, image, info: { name, position, socials: { linkedIn, twitter } } } = teamInfo
    const [isHover, setIsHover] = useState(false)

    return (
        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} className="relative my-3 w-full justify-center items-center cursor-pointer rounded-[50px]" style={{ backgroundColor: bgColor }}>
            <img src={image} alt="" className="w-full h-full rounded-[50px]"></img>

            <div className={`absolute bottom-0 w-full p-5 rounded-[50px] bg-gradient-to-t ${bgGradient} from-[#E6ECF5] via-[#E6ECF5] to-transparent transition duration-1000 ease-in-out ${isHover ? 'opacity-80 text-black' : 'opacity-0 text-transparent'}`}>
                <h3 className="text-sm font-medium"> {position} </h3>
                <h1 className="text-xl font-bold"> {name} </h1>
                <div className="flex justify-start md:justify-center space-x-2">
                    <a href={twitter}>
                        <GrTwitter className="" />
                    </a>
                    <a href={linkedIn}>
                        <GrLinkedinOption className="" />
                    </a>
                </div>
            </div>
        </div>

    )
}

export default TeamMember