import React from 'react'

const Button = ({ link }) => {
    const { content, anchor } = link
    return (
        <div className="flex justify-center items-center">
            <a href={anchor} className="text-xs md:text-base text-white font-bold py-2 px-8 md:px-16 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 text-center"  style={{backgroundColor: "rgb(214,62,135)"}}> {content} </a>
        </div>
        
    )
}

export default Button