import { useState } from 'react';

const FaqQuestion = ({ question, answer }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`border-b-2 border-gray-800 py-3 ${isExpanded ? '' : 'h-16'}`}>
            <div className="flex items-center justify-between">
                <h3 className="text-base text-left text-gray-800 font-bold">{question}</h3>
                <button className="font-medium text-base ml-7" style={{ color: '#FF7200' }} onClick={toggleExpansion}>
                    {isExpanded ? '-' : '+'}
                </button>
            </div>
            {isExpanded && <p className="mt-2 font-normal text-sm text-left text-gray-500">{answer} </p>}
        </div>
    );
};

export default FaqQuestion;
