import { useEffect, useState } from "react"
import Shadow from '../assets/images/shadow.png'

const Content = ({ bg, image, holos }) => {

    const getImageClass = (holo, holos, isSmallScreen) => {
        let className = `absolute animate-${holo.animate} `;

        if (holo.order === 1) {
            if (holos.position === 'left') {
                className += isSmallScreen ? 'top-20 right-5' : 'top-20 left-10';
            } else if (holos.position === 'right') {
                className += isSmallScreen ? 'top-20 left-10' : 'top-20 right-5';
            }
        } else if (holo.order === 2) {
            if (holos.position === 'left') {
                className += isSmallScreen ? 'bottom-0 left-10' : 'bottom-0 right-5';
            } else if (holos.position === 'right') {
                className += isSmallScreen ? 'bottom-0 right-10' : 'bottom-0 left-5';
            }
        }

        return className;
    }

    const getHoloSizeClass = (holo, isSmallScreen) => {

        let className = ``
        if (isSmallScreen === false) {
            switch (holo.size) {
                case 8:
                    className += 'w-8'
                    break
                case 10:
                    className += 'w-10'
                    break
                default:
                    className += 'w-12'
            }
        }

        return className

    }


    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        setIsSmallScreen(mediaQuery.matches);

        const handleMediaQueryChange = (e) => {
            setIsSmallScreen(e.matches);
        }

        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        }
    }, []);

    return (
        <div className="relative flex justify-center items-center w-full mt-5">
            <div className="flex justify-center items-center w-full md:w-9/12 px-8 py-10" style={{ backgroundColor: bg, borderRadius: '70px' }}>
                <div className="relative">
                    <video className="w-full" autoPlay muted loop>
                        <source src={image} type="video/webm" />
                        Your browser does not support the video tag.
                    </video>
                    
                    <img className="absolute bottom-[-18px] w-full " alt="..." src={Shadow}></img>
                </div>
            </div>

            {holos && holos.icons.map((holo, index) => (
                <img key={index} src={holo.icon} alt="..."
                    className={`${getImageClass(holo, holos, isSmallScreen)} ${getHoloSizeClass(holo, isSmallScreen)}`}>
                </img>
            ))}
        </div>
    )
}

export default Content