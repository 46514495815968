import React from 'react'

const SectionHeader = ({ titles, centered }) => {

    return (
        <div className={`flex flex-col md:justify-center ${centered ? 'items-center' : 'items-start'}`}>
            {titles && Array.isArray(titles) && titles.map((title, index) => (
                <h1 key={index} className={`text-5xl lg:text-6xl font-bold text-gray-800 tracking-wider ${centered ? 'text-center' : 'text-left'}`}>{title}</h1>
            ))}
        </div>


    )
}

export default SectionHeader