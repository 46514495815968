import SectionSubTitle from "../../components/SectionSubTitle";
import PdfButton from "../../components/PDFButton";
import Button from "../../components/Button";
import SectionHeader from "../../components/SectionHeader";
import CarouselItem from "../../components/CarouselItem";
import AboutIcon from "../../assets/images/about.png";
import Holo11 from "../../assets/images/holo-copy-11.png";
import Holo12 from "../../assets/images/holo-copy-12.png";
import Holo13 from "../../assets/images/holo-copy-13.png";
import Holo14 from "../../assets/images/holo-copy-14.png";
import Holo15 from "../../assets/images/holo-copy-15.png";
import Holo16 from "../../assets/images/holo-copy-16.png";
import Shadow from "../../assets/images/shadow.png";

const About = ({ setRef }) => {
  const PDFButtonLink = {
    content: 'Read the Whitepaper',
    pdfUrl: '/pdf/Likha-Whitepaper.pdf',
  }

  const link = {
    content: "Go to Marketplace",
    anchor: "https://www.likhanft.io/",
  };

  const titles = ["NFTS MADE EASY"];
  const carouselDetails = [
    {
      title: "Onboarded creators",
      subTitle: "100+",
      description: `We only get the best of the best, so don't expect mediocre art here!`,
      icon: Holo14,
      bg: "#F9D5FF",
      right: 0,
    },

    {
      title: "Monthly Growth Rate",
      subTitle: "120%",
      description: `More monthly visitors, more revenue for the artists!`,
      icon: Holo16,
      bg: "#FFE1CB",
      right: -3,
    },

    {
      title: "Market Value",
      subTitle: "$3.1B",
      description: `Big boys are coming in, what are you waiting for?`,
      icon: Holo15,
      bg: "#D4B4F4",
      right: -3,
    },
  ];

  return (
    <div
      ref={setRef}
      className="py-6 px-6 my-10 lg:mx-32 xl:mx-36 lg:my-20 relative "
    >
      <div className="flex flex-col lg:flex-row lg:space-x-10">
        <div className="lg:w-7/12 w-full">
          <div className="flex flex-col md:items-start">
            <SectionSubTitle className="lg:ml-10" title="ABOUT" />
            <SectionHeader titles={titles} />
          </div>

          <div className="lg:ml-10 relative lg:mt-8">
            <img
              className="absolute w-20 top-0 left-[-100px] animate-bounce animate-bounce duration-75"
              alt="..."
              src={Holo11}
            ></img>
            <img
              className="absolute w-8 bottom-0 left-[-100px]  animate-spin duration-50"
              alt="..."
              src={Holo13}
            ></img>
            <p className="text-sm lg:text-lg font-bold text-gray-800 tracking-wider text-left ">
              {" "}
              Our NFT marketplace is dedicated to providing a secure and
              accessible platform for trading unique digital assets.{" "}
            </p>
            <p className="mt-3 text-sm lg:text-lg font-bold text-gray-800 tracking-wider text-left">
              With a focus on fostering a vibrant community of artists,
              collectors, and enthusiasts, we are shaping the future of how we
              buy, sell, and experience NFTs. Join us on our journey to
              revolutionize the world of digital art and collectibles.
            </p>
          </div>

          <div className="flex justify-center mt-5 gap-3">
            <PdfButton link={PDFButtonLink} /> <Button link={link} />
          </div>
        </div>

        <div className="lg:flex p-2 relative justify-center items-center hidden lg:w-2/5 w-full">
          <img
            className="absolute w-8 top-[-20px] right-[-25px] animate-spin duration-2000"
            alt="..."
            src={Holo12}
          ></img>
          <div className="relative">
            <img className="w-full " alt="..." src={AboutIcon}></img>
            <img
              className="absolute bottom-[-50px] w-full "
              alt="..."
              src={Shadow}
            ></img>
          </div>
        </div>
      </div>

      <div className="flex justify-start lg:justify-center carousel carousel-center space-x-8 rounded-box pt-10 md:pt-20 w-full">
        {carouselDetails &&
          carouselDetails.map((details, index) => (
            <CarouselItem key={index} details={details} />
          ))}
      </div>
    </div>
  );
};

export default About;
