import React from 'react'

const RoadMapText = ({ title, subtitle, parag }) => {
    return (
        <div className="w-full mt-5 w-full md:w-9/12">
            <h3 className="text-2xl font-bold tracking-wider text-left" style={{ color: '#FF7200' }} > {title} </h3>
            <h3 className="text-3xl font-bold text-gray-800 tracking-wider text-left"> {subtitle} </h3>
            <p className="text-sm mt-2 text-left font-medium text-gray-400">{parag}</p>
        </div>
    )
}

export default RoadMapText