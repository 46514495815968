import React from 'react'

const SectionSubTitle = ({ title }) => {
    return (
        <div>
            <div className="flex items-center">
                <h3 className="text-2xl font-bold tracking-wider text-left" style={{ color: '#FF7200' }} > {title} </h3>
                <div className="ml-5 h-0.5 w-20" style={{ backgroundColor: '#FF7200' }}></div>
            </div>
        </div>
    )
}

export default SectionSubTitle