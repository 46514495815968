import Holo2 from "../../assets/images/holo-copy-2.png";
import Holo3 from "../../assets/images/holo-copy-3.png";
import Holo4 from "../../assets/images/holo-copy-4.png";
import Holo9 from "../../assets/images/holo-copy-9.png";
import Holo10 from "../../assets/images/holo-copy-10.png";
import Layer2 from "../../assets/images/layer-2.png";
import Circle from "../../assets/images/circle.png";

const Header = ({ setRef }) => {
  return (
    <div ref={setRef} className="relative">
      <img
        className="absolute animate-pulse right-0 top-[-40px] object-cover lg:object-contain h-full lg:w-7/12"
        alt="..."
        src={Circle}
      ></img>
      <div className="py-6 px-6 md:m-20 lg:mx-48 lg:pb-56 lg:pt-40">
        <div className="flex relative">
          <img
            className="w-36 hidden lg:block absolute top-[-50px] left-[-100px] transition duration-150 ease-out hover:skew-y-12"
            alt="..."
            src={Layer2}
          ></img>

          <div className="lg:w-2/3 w-full">
            <div className="flex flex-col items-start">
              <h1 className="text-6xl xl:text-8xl font-bold text-gray-800 tracking-wider">
                {" "}
                DISCOVER{" "}
              </h1>
              <div className="flex items-center space-x-2 sm:space-x-10">
                <div
                  className="mr-1 sm:mr-5 h-1 w-8 sm:w-16 sm:w-20"
                  style={{ backgroundColor: "#FF7200" }}
                ></div>
                <h1 className="text-6xl xl:text-8xl font-bold text-gray-800 tracking-wider">
                  {" "}
                  COLLECT{" "}
                </h1>
              </div>
              <div className="relative">
                <h1 className="text-6xl xl:text-8xl font-bold text-gray-800 tracking-wider">
                  {" "}
                  & CREATE{" "}
                </h1>
                <h6 className="absolute bottom-[-30px] right-[-20px] text-lg sm:text-xl mt-3 font-bold text-gray-800">
                  {" "}
                  digital collectibles.{" "}
                </h6>
              </div>
            </div>
          </div>

          <img
            className="w-32 hidden lg:block absolute bottom-[-200px] left-[-120px] transition duration-150 ease-out hover:skew-x-6"
            alt="..."
            src={Holo10}
          ></img>
        </div>
      </div>

      <div className="flex items-center justify-center p-10 md:w-full lg:w-2/5 lg:absolute lg:right-5 lg:top-20">
        <img
          className="transition duration-150 ease-out hover:skew-x-12"
          alt="..."
          src={Holo9}
        ></img>
        <img
          className="absolute hidden lg:block w-12 top-[-15px] left-36 animate-bounce"
          alt="..."
          src={Holo4}
        ></img>
        <img
          className="absolute w-8 top-12 right-10 animate-bounce"
          alt="..."
          src={Holo3}
        ></img>
        <img
          className="absolute w-8 bottom-0 left-20 animate-bounce"
          alt="..."
          src={Holo2}
        ></img>
      </div>
    </div>
  );
};

export default Header;
