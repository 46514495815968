import Shadow from '../assets/images/shadow.png'

const CarouselItem = ({ details }) => {

    const { title, subTitle, description, icon, bg, right } = details

    return (
        <div className="carousel-item">
            <div className="relative w-[200px] sm:w-[320px] sm:w-[320px] md:w-[320px] lg:w-[250px] xl:w-[290px] 2xl:w-[320px] pl-8 sm:pl-16 lg:pl-10 xl:pl-16 pr-16 sm:pr-36 lg:pr-24 xl:pr-36 pt-5 pb-5 lg:pb-4 xl:p-b" style={{ backgroundColor: bg, borderRadius: '20%' }}>
                <h3 className="text:lg sm:text-xl lg:text-lg xl:text-xl font-bold text-gray-800 tracking-wider text-left"> {title} </h3>
                <h3 className="text-3xl sm:text-4xl lg:text-3xl xl:text-4xl font-bold text-gray-800 tracking-wider text-left"> {subTitle} </h3>
                <p className="text-[10px] font-bold text-gray-500 tracking-wider text-left"> {description} </p>
                <img alt=".." src={icon} className={`w-5/12 absolute top-0 ${right === 0 ? `right-[-10px]` : `right-[-35px] top-[-20px]`}`} />
                <img className="absolute bottom-10 w-1/2 right-0" alt="..." src={Shadow}></img>
            </div>
        </div>
    )
}

export default CarouselItem