const PdfButton = ({ link }) => {
    const { content, pdfUrl } = link

    const handleClick = () => {
        window.open(pdfUrl, '_blank', 'fullscreen=yes');
    };

    return (
        <div className="flex justify-center items-center">
            <button
                className="text-xs md:text-base text-white font-bold py-2 px-8 md:px-16 rounded-full bg-gradient-to-r from-purple-500 to-pink-500"
                style={{ backgroundColor: 'rgb(214, 62, 135)' }}
                onClick={handleClick}
            >
                {content}
            </button>
        </div>
    )
}

export default PdfButton
