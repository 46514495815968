import "./App.css";
import { useRef } from "react";
import Nav from "./sections/nav/Nav";
import Header from "./sections/header/Header";
import RoadMap from "./sections/road-map/RoadMap";
import Team from "./sections/team/Team";
import Join from "./sections/join/Join";
import Faq from "./sections/faq/Faq";
import Footer from "./sections/footer/Footer";
import About from "./sections/about/About";
import UpLine from "./components/UpLine";
import GalleryCollection from "./sections/gallery/GalleryCollection";

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const galleryRef = useRef(null);
  const roadmapRef = useRef(null);
  const teamRef = useRef(null);
  const communityRef = useRef(null);
  const faqRef = useRef(null);

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div className="App overflow-y-hidden">
      <UpLine />
      <Nav
        onsetHome={() => handleScroll(homeRef)}
        onsetAbout={() => handleScroll(aboutRef)}
        onsetGallery={() => handleScroll(galleryRef)}
        onsetRoadMap={() => handleScroll(roadmapRef)}
        onsetTeam={() => handleScroll(teamRef)}
        onsetCommunity={() => handleScroll(communityRef)}
        onsetFAQ={() => handleScroll(faqRef)}
      />
      <section ref={homeRef}>
        <Header />
      </section>

      <section ref={aboutRef}>
        <About />
      </section>

      <section ref={galleryRef}>
        <GalleryCollection />
      </section>

      <section ref={roadmapRef}>
        <RoadMap />
      </section>

      <section ref={teamRef}>
        <Team />
      </section>

      <section ref={communityRef}>
        <Join />
      </section>

      <section ref={faqRef}>
        <Faq />
      </section>
      <Footer />
    </div>
  );
}

export default App;
